.article {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: black;
}

.picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-size: contain;
}

.header {
    font-size: 80px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
}